import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  DEFAULT_IMG_URL,
  DEFAULT_IMG_URL_WEBP,
  SUPPLIERS__FROMS
} from '../../constants';
import { actions, asyncActions } from '../../redux';
import { getSuppliersCounts, getWordDeclination, getNames } from '../../utils';
import CountryFlag from '../CountryFlag';
import Image from '../Image';
import Price from '../Price';

import { itemContext } from './Catalog';
import { getReasonableOffer, getPositionName, getPositionInfo } from './helpers';

const Item = ({ data, main }) => {
  const {
    offers,
    photo,
    name,
    id,
    country,
    name_ru,
    winery,
    type,
    subtype,
    wine_type,
    description,
    region,
    brand,
    grapes,
    type_ru,
    change_main_name,
    filteredVolumes,
    scrollPosition,
    avg_reviews_rating,
    resize_photo,
    webp_photo
  } = {
    ...data,
  };

  const router = useRouter();

  const {
    query: { ordering },
  } = router;

  const isSortingByPriceOn = !!ordering;

  const city = useSelector((state) => state.profile.city);

  const suppliersCounts = useMemo(() => {
    const offersFilteredByCity = offers.filter((item) =>
      item.cities.find(({ code }) => code === city)
    );
    return getSuppliersCounts(offersFilteredByCity);
  }, [offers, city]);

  const isPositionAvailable = getPositionInfo(city, offers);

  const { setItem, toggle } = useContext(itemContext);
  const dispatch = useDispatch();

  const declinatedSupplier = getWordDeclination(suppliersCounts, SUPPLIERS__FROMS);
  const { price, volume } = getReasonableOffer({
    offers,
    code: city,
    filteredVolumes,
    isSortingByPriceOn,
  });

  const itemClassnames = cn('catalog__item', {
    'catalog__item--wide': main,
  });

  const { mainName } = getNames(change_main_name, name, name_ru);
  const positionName = getPositionName(type_ru, mainName);

  const onClickHandler = (id) => {
    if (id) dispatch(asyncActions.updateViewsCount(id));
    toggle();
    setItem({
      offers,
      photo: resize_photo || photo,
      name,
      id,
      country,
      name_ru,
      winery,
      type,
      subtype,
      wine_type,
      description,
      region,
      brand,
      grapes,
      type_ru,
      change_main_name,
      volume,
      avg_reviews_rating,
      webp_photo
    });
  };

  const itemImgSrc = resize_photo || photo || DEFAULT_IMG_URL;

  const onItemClickHandler = () => dispatch(actions.setScrollPosition(scrollPosition));

  return (
    <li itemScope itemType="http://schema.org/Product" className={itemClassnames}>
      <meta itemProp="name" content={positionName} />
      <meta itemProp="image" content={itemImgSrc} />
      <button onClick={() => onClickHandler(id)} className="catalog__item-hover-text">
        Быстрый просмотр
      </button>
      <Link href={`/positions/${id}`}>
        <a className="catalog__item-link" onClick={onItemClickHandler}>
          <div className="catalog__item-inner">
            <div className="catalog__item-img-wrapper">
              <CountryFlag className="catalog__item-region-flag" country={country} />
              <div className="catalog__item-image">
                <Image
                  key={id}
                  src={itemImgSrc}
                  photoWebp={webp_photo || DEFAULT_IMG_URL_WEBP}
                  alt={name}
                  title={name}
                  addPlaceholder={false}
                />
              </div>
            </div>
            <meta itemProp="brand" content={brand ? brand.name : ''} />
            <div
              itemProp="offers"
              itemScope
              itemType="http://schema.org/Offer"
              className="catalog__item-info"
            >
              <span className="catalog__item-title">{positionName}</span>
              <meta itemProp="price" content={price} />
              <meta itemProp="priceCurrency" content="RUB" />
              <link
                itemProp="availability"
                href={
                  isPositionAvailable ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'
                }
              />
              <div className="catalog__info-wrapper">
                <div className="catalog__item-wp">
                  {volume && <p className="catalog__item-measure">{`${volume} л`}</p>}
                </div>
                {isPositionAvailable ? (
                  <>
                    <p className="catalog__item-provider">{`${suppliersCounts} ${declinatedSupplier}`}</p>
                    <div className="catalog__item-price-info">
                      от <Price classname="catalog__item-price" price={price} />
                    </div>
                  </>
                ) : (
                  <div className="catalog__info-wrapper catalog__info-wrapper--center">
                    <p className="catalog__warning-title">Нет в наличии</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </a>
      </Link>
    </li>
  );
};
export default Item;
