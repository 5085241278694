import { parseMoney } from './helpers/parseMoney';

const Price = ({ price = 0, classname, total = false }) => {
  return (
    <div className={classname}>
      <span className="price__full">
        {parseMoney(price).full}
        <p className="penny">{`${parseMoney(price).decimal}`}</p>
      </span>
      <span className="measurements">
          &nbsp;&nbsp;&#8381;<span className="quantity">{!total && '/шт'}</span>
      </span>
    </div>
  );
};

export default Price;
