import { useEffect, useState } from 'react';

export const useLoadingLazy = () => {
  const [isLoadingLazySupported, setIsLoadingLazySupported] = useState(false);

  useEffect(() => {
    if ('loading' in HTMLImageElement.prototype) {
      setIsLoadingLazySupported(true);
    }
  }, []);

  return isLoadingLazySupported;
};
