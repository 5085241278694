import classNames from 'classnames';

const CountryFlag = ({ country, className }) => {
  const elementClassNames = classNames(className, {
    [`${className}--ab`]: country?.code === 'ab',
    [`${className}--os`]: country?.code === 'os',
  });

  return (
    <span
      title={country?.name_ru}
      className={`${elementClassNames} flag-icon-background flag-icon-${country?.code}`}
    ></span>
  );
};
export default CountryFlag;
