import dynamic from 'next/dynamic';
import { useState } from 'react';

import Modal from '../Modal';

const PositionPopupContent = dynamic(() => import('./PositionPopupContent'));

const PositionPopup = ({
  offers,
  isShowing,
  toggle,
  name,
  name_ru,
  winery,
  photo,
  id,
  subtype,
  country,
  type,
  wine_type,
  description,
  region,
  brand,
  grapes,
  type_ru,
  changeMainName,
  volume,
  avg_reviews_rating,
}) => {
  const [infoShowing, setInfoShowing] = useState(false);
  const onInfoClick = () => setInfoShowing(!infoShowing);

  return (
    <Modal toggle={toggle} isShowing={isShowing}>
      <PositionPopupContent
        infoShowing={infoShowing}
        onInfoClick={onInfoClick}
        offers={offers}
        name={name}
        name_ru={name_ru}
        winery={winery}
        photo={photo}
        id={id}
        type={type}
        subtype={subtype}
        wine_type={wine_type}
        description={description}
        region={region}
        brand={brand}
        grapes={grapes}
        country={country}
        type_ru={type_ru}
        changeMainName={changeMainName}
        volume={volume}
        avg_reviews_rating={avg_reviews_rating}
      />
    </Modal>
  );
};

export default PositionPopup;
