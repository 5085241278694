import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useState } from 'react';

import { DEFAULT_IMG_URL } from '../../constants';
import { useLoadingLazy } from '../../hooks/useLoadingLazy';

const ImgWithFallback = dynamic(() => import('../ImgWithFallback'));

const Image = ({ src, photoWebp, alt, title, addPlaceholder = true }) => {
  const isLoadingLazySupported = useLoadingLazy();
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoadHanlder = () => setIsLoaded(true);

  const placeHolderClassnames = classNames('image__placeholder', {
    'image__placeholder--hide': isLoaded,
  });

  const imageClassnames = classNames('image__main-img', {
    'image__main-img--show': isLoaded,
  });

  return (
    <div className="image">
      {addPlaceholder && (
        <div className={placeHolderClassnames}>
          <img src={DEFAULT_IMG_URL} alt={alt} title={title} />
        </div>
      )}
      <div className={imageClassnames}>
        {isLoadingLazySupported ? (
          <ImgWithFallback
            photoSrc={src}
            photoWebp={photoWebp}
            loading="lazy"
            onLoad={onLoadHanlder}
            alt={alt}
            title={title}
          />
        ) : (
          <ImgWithFallback
            photoWebp={photoWebp}
            className="lazyload"
            onLoad={onLoadHanlder}
            dataSrc={src}
            dataSizes="auto"
            alt={alt}
            title={title}
          />
        )}
      </div>
    </div>
  );
};

export default Image;
