import cn from 'classnames';
import Link from 'next/link';

const CatalogPaginatorButton = ({ disabled, label, page, currentPage, href }) => {
  const buttonClassname = cn('paginator__button', {
    'paginator__button--disabled': disabled,
    'paginator__button--empty': label === 'dots',
    'paginator__button--current': Number(currentPage) === page,
  });

  return href ? (
    <Link href={href}>
      <a className={buttonClassname}>{label}</a>
    </Link>
  ) : (
    <span className={buttonClassname}>{label}</span>
  );
};

export default CatalogPaginatorButton;
