import cn from 'classnames';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';

import useModal from '../../hooks/useModal';
import useScroll from '../../hooks/useScroll';
import PositionPopup from '../PositionPopup/PositionPopup';

import CatalogItem from './CatalogItem';
import CatalogPaginator from './CatalogPaginator';

export const itemContext = createContext();

const Catalog = ({ paginator = false, main = false }) => {
  const [item, setItem] = useState({});
  const scrollPosition = useScroll();
  const {
    data: { products, currentQuery },
  } = useSelector((state) => state);

  const filteredVolumesFromQuery = currentQuery.volume || '';
  const filteredVolumes = filteredVolumesFromQuery
    .split(', ')
    .filter((item) => !!item)
    .map((item) => Number(item));

  const catalogClassnames = cn('catalog__list', {
    'catalog__list--wide': main,
  });

  const { isShowing, toggle } = useModal();

  return products?.results?.length === 0 ? (
    <h3 className="catalog__title catalog__title--empty">По вашему запросу ничего не найдено</h3>
  ) : (
    <itemContext.Provider value={{ item, setItem, toggle }}>
      <PositionPopup
        offers={item.offers || null}
        isShowing={isShowing}
        toggle={toggle}
        name={item.name}
        name_ru={item.name_ru}
        winery={item.winery}
        photo={item.photo}
        id={item.id}
        type={item.type}
        subtype={item.subtype}
        wine_type={item.wine_type}
        description={item.description}
        region={item.region}
        brand={item.brand}
        grapes={item.grapes}
        type_ru={item.type_ru}
        changeMainName={item.change_main_name}
        volume={item.volume}
        avg_reviews_rating={item.avg_reviews_rating}
      />
      <div className="catalog" id="catalog">
        <ul itemScope="" itemType="http://schema.org/Product" className={catalogClassnames}>
          {products.results?.map((item, id) => (
            <CatalogItem main={main} key={id} data={{ ...item, filteredVolumes, scrollPosition }} />
          ))}
        </ul>
        <div className="catalog__paginator">{paginator && <CatalogPaginator />}</div>
      </div>
    </itemContext.Provider>
  );
};

export default Catalog;
