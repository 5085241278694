import cn from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';

const Icon = dynamic(() => import('../Icon'));

const CatalogPaginatorArrow = ({ disabled, name, href }) => {
  const classnames = cn('paginator__button', {
    [`paginator__button--${name}`]: name,
    'paginator__button--disabled': disabled,
  });

  return disabled ? (
    <Icon className={classnames} name={`paginator__${name}`} />
  ) : (
    <Link href={href}>
      <a>
        <Icon className={classnames} name={`paginator__${name}`} />
      </a>
    </Link>
  );
};

export default CatalogPaginatorArrow;
