export const parseMoney = (money) => ({
  full: String(Number(money).toFixed(2))
    .replace(/\.?\d{0,2}$/, '')
    .split('')
    .reverse()
    .join('')
    .match(/.{1,3}/g)
    .join('\xa0')
    .split('')
    .reverse()
    .join(''),
  decimal: Number(money).toFixed(2).replace(/\d*\./, ''),
});
