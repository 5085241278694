import { useRouter } from 'next/router';
import { useStore } from 'react-redux';

import { DEFAULT_PAGE_SIZE } from '../../constants';

import CatalogPaginatorArrow from './CatalogPaginatorArrow';
import CatalogPaginatorButton from './CatalogPaginatorButton';
import { getButtonsList, getPaginatorHref } from './helpers';

const CatalogPaginator = () => {
  const router = useRouter();

  const {
    data: { products, currentQuery, currentType, currentPosition },
  } = useStore().getState();

  const { page_size, page } = currentQuery;
  const { count } = products;
  const currentPage = page || 1;
  const pageCount = Math.ceil(count / (page_size ? page_size : DEFAULT_PAGE_SIZE));

  return (
    <div className="paginator">
      <div className="paginator__buttons-wrapper">
        <CatalogPaginatorArrow
          name="prev"
          href={getPaginatorHref(
            Number(currentPage) - 1,
            currentPosition,
            currentQuery,
            router,
            currentType
          )}
          disabled={Number(currentPage) - 1 < 1}
        />
        {getButtonsList(pageCount, currentPage).map(({ page, label }, index) => (
          <CatalogPaginatorButton
            key={index}
            href={getPaginatorHref(page, currentPosition, currentQuery, router, currentType)}
            page={page}
            label={label}
            currentPage={currentPage}
          />
        ))}
        <CatalogPaginatorArrow
          name="next"
          href={getPaginatorHref(
            Number(currentPage) + 1,
            currentPosition,
            currentQuery,
            router,
            currentType
          )}
          disabled={Number(currentPage) + 1 > pageCount}
        />
      </div>
    </div>
  );
};

export default CatalogPaginator;
